<template>
	<div
		class="payments_form-container payments_form-container_mining"
		v-if="computedValue === `ko`"
	>
		<img :src="logoBlack" />
		<h4>상품을 신청하기 위하여 필요한 정보를 넣어주세요.</h4>
		<h2>구매 항목</h2>
		<div class="form_group form_goods">
			<div class="form_title">※상품</div>
			<div class="form_content">마이닝 충전</div>
		</div>
		<div class="form_group form_pay_detail">
			<div class="form_title">※결제상세</div>
			<div class="form_content">
				<div>
					<input type="text" :value="mining" disabled />
					<label>mining</label>
				</div>
				<div>
					<label>금액 : </label>
					<input type="text" :value="mining * 500" disabled />
					<label>원</label>
				</div>
			</div>
		</div>
		<div class="form_group form_radio form_pay_method form_pay_method-mining">
			<h3>※결제 수단</h3>
			<div class="form_content">
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="card"
							v-model="form.methodType"
							value="card"
						/>
						<label for="card" class="radio_check-btn">✔</label>
					</div>
					<label>신용카드</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="bank"
							v-model="form.methodType"
							value="bank"
						/>
						<label for="bank" class="radio_check-btn">✔</label>
					</div>
					<label>계좌이체</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="vbank"
							v-model="form.methodType"
							value="vbank"
						/>
						<label for="vbank" class="radio_check-btn">✔</label>
					</div>
					<label>가상계좌</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="naverpayCard"
							v-model="form.methodType"
							value="naverpayCard"
						/>
						<label for="naverpayCard" class="radio_check-btn">✔</label>
					</div>
					<label>네이버페이</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="kakaopay"
							v-model="form.methodType"
							value="kakaopay"
						/>
						<label for="kakaopay" class="radio_check-btn">✔</label>
					</div>
					<label>카카오페이</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="samsungpayCard"
							v-model="form.methodType"
							value="samsungpayCard"
						/>
						<label for="samsungpayCard" class="radio_check-btn">✔</label>
					</div>
					<label>삼성페이</label>
				</div>
			</div>
		</div>
		<div class="form-submit">
			<input
				type="button"
				value="결제하기"
				@click="serverAuth()"
				:class="{ submitDisabled: !allCheck }"
				:disabled="!allCheck || isPaymentInProgress"
			/>
		</div>
	</div>
	<div
		class="payments_form-container payments_form-container_mining"
		v-else-if="computedValue === `en`"
	>
		<img :src="logoBlack" />
		<h4>Please enter the necessary information to apply for the product.</h4>
		<h2>Purchase Items</h2>
		<div class="form_group form_goods">
			<div class="form_title">※Product</div>
			<div class="form_content">Mining Recharge</div>
		</div>
		<div class="form_group form_pay_detail">
			<div class="form_title">※Payment Details</div>
			<div class="form_content">
				<div>
					<input type="text" :value="mining" disabled />
					<label>mining</label>
				</div>
				<div>
					<label>Amount : </label>
					<input type="text" :value="mining * 500" disabled />
					<label>KRW</label>
				</div>
			</div>
		</div>
		<div class="form_group form_radio form_pay_method form_pay_method-mining">
			<h3>※Payment Method</h3>
			<div class="form_content">
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="card"
							v-model="form.methodType"
							value="card"
						/>
						<label for="card" class="radio_check-btn">✔</label>
					</div>
					<label>Credit Card</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="bank"
							v-model="form.methodType"
							value="bank"
						/>
						<label for="bank" class="radio_check-btn">✔</label>
					</div>
					<label>Bank Transfer</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="vbank"
							v-model="form.methodType"
							value="vbank"
						/>
						<label for="vbank" class="radio_check-btn">✔</label>
					</div>
					<label>Virtual Account</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="naverpayCard"
							v-model="form.methodType"
							value="naverpayCard"
						/>
						<label for="naverpayCard" class="radio_check-btn">✔</label>
					</div>
					<label>Naver Pay</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="kakaopay"
							v-model="form.methodType"
							value="kakaopay"
						/>
						<label for="kakaopay" class="radio_check-btn">✔</label>
					</div>
					<label>Kakao Pay</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="samsungpayCard"
							v-model="form.methodType"
							value="samsungpayCard"
						/>
						<label for="samsungpayCard" class="radio_check-btn">✔</label>
					</div>
					<label>Samsung Pay</label>
				</div>
			</div>
		</div>
		<div class="form-submit">
			<input
				type="button"
				value="Make a Payment"
				@click="serverAuth()"
				:class="{ submitDisabled: !allCheck }"
				:disabled="!allCheck || isPaymentInProgress"
			/>
		</div>
	</div>
</template>

<script>
import store from '@/store/index.js';
import logoBlack from '@/assets/images/fctslogoblack.svg';
import paymentsService from '../../services/paymentsService';
import fctsbuyer from '../../services/couponList';

export default {
	name: 'FctsMinjeongPaymentsForm',
	props: {
		mining: {
			type: Number,
		},
	},
	data() {
		return {
			form: {
				userId: store.state.userid,
				prdctName: 'bi_mining',
				methodType: '',
			},
			logoBlack,
			isPaymentInProgress: false, // 결제 진행 중임을 표시하는 변수
			paymentCompleted: false,
		};
	},
	mounted() {},
	computed: {
		methodTypeState() {
			if (this.form.methodType !== '') {
				return true;
			} else {
				return null;
			}
		},
		allCheck() {
			if (this.methodTypeState == true) {
				return true;
			} else {
				return false;
			}
		},
		computedValue() {
			return this.$store.state.language;
		},
	},

	methods: {
		async serverAuth() {
			this.isPaymentInProgress = true; // 결제가 진행 중임을 표시
			this.paymentCompleted = false;

			const formData = {
				userId: this.form.userId,
				prdctName: this.form.prdctName,
				periodUse: '',
				ordererName: '',
				ordererPhoneNum: '',
				ordererEmail: '',
				totalAmount: this.mining * 500,
				methodType: this.form.methodType,
				startDate: '',
				endDate: '',
				totalDscntAmount: '',
				vbankHolder: '포체인스 주식회사',
			};

			try {
				const res = await paymentsService.getPaymentsKey();
				if (typeof window !== 'undefined') {
					const pay_obj = window;
					const { AUTHNICE } = pay_obj;

					AUTHNICE.requestPay({
						clientId: res.data.clientId,
						method: formData.methodType,
						orderId: res.data.orderId,
						amount: formData.totalAmount,
						goodsName: formData.prdctName,
						vbankHolder: formData.ordererName,
						returnUrl: `${process.env.VUE_APP_API_URL}/api/payments/serverAuth`,
						fnError: async function (result) {
							console.log(result.errorMsg);
							try {
								// 데이터베이스에서 userId를 사용하여 데이터 삭제
								await paymentsService.deleteCancelPayments(formData.userId);
								console.log(
									'결제가 취소되고 데이터베이스 정보가 삭제되었습니다.',
								);
							} catch (error) {
								console.error('결제 취소 실패:', error);
							}
						},
					});
					// 결제 창이 닫힐 때의 이벤트 처리
					window.addEventListener('beforeunload', async event => {
						// 사용자가 결제를 완료하지 않은 경우에만 데이터를 삭제하도록 조건 추가
						if (!this.paymentCompleted) {
							event.preventDefault(); // 브라우저가 즉시 닫히는 것을 방지하기 위해 필요합니다.
							event.returnValue =
								'이 페이지를 벗어나면 결제가 취소될 수 있습니다.';
							try {
								console.log('결제 정보가 삭제되었습니다.');
							} catch (error) {
								console.error('결제 정보 삭제 실패:', error);
							}
						} else {
							setTimeout(() => {
								if (typeof window !== 'undefined') {
									window.close();
								}
							}, 1000);

							//데이터는 결제 완료 후 창이 자동으로 닫힌 후에 서버로 전송
							await fctsbuyer.getbuy(formData);
						}
					});
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.isPaymentInProgress = false; // 결제 완료 후 플래그 초기화
				this.paymentCompleted = true; // 결제가 성공적으로 완료되었을 때만 설정
			}
		},
	},
};
</script>

<style></style>
