<template>
	<div class="payments_popup" v-if="computedValue === `ko`">
		<template v-if="isUserlogin">
			<payments-form-mining :mining="this.mining"></payments-form-mining>
		</template>
		<template v-else>
			<div class="payments_popup_login">
				<header>
					<img :src="logoIcon" />
				</header>
				<main>
					<h1>로그인</h1>
					<h3>맞춤 서비스를 제공하기 위해 로그인이 필요합니다.</h3>
					<login-form :isPopup="true"></login-form>
				</main>
			</div>
		</template>
	</div>
	<div class="payments_popup" v-else-if="computedValue === `en`">
		<template v-if="isUserlogin">
			<payments-form-mining :mining="this.mining"></payments-form-mining>
		</template>
		<template v-else>
			<div class="payments_popup_login">
				<header>
					<img :src="logoIcon" />
				</header>
				<main>
					<h1>Login</h1>
					<h3>You need to log in to receive personalized services.</h3>
					<login-form :isPopup="true"></login-form>
				</main>
			</div>
		</template>
	</div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
import PaymentsFormMining from '../components/payments/PaymentsFormMining.vue';
import logoIcon from '@/assets/images/new-fctslogoWhite.svg';
export default {
	components: { LoginForm, PaymentsFormMining },
	name: 'FctsPaymentsPopup',

	data() {
		return {
			logoIcon,
			mining: 2,
		};
	},
	mounted() {
		// 자식 창에서 데이터 추출
		const urlParams = new URLSearchParams(window.location.search);
		const receivedData = urlParams.get('data');
		this.mining = receivedData;
	},

	computed: {
		// 로그인 분기 처리
		isUserlogin() {
			return this.$store.getters.isLogin;
		},
		computedValue() {
			return this.$store.state.language;
		},
	},
};
</script>

<style></style>
